












import { computed, PropType } from '@nuxtjs/composition-api';
import { ISbStoryData, ISbRichtext } from '@storyblok/js/dist/types';
import { useStoryblokStyles } from '~/modules/storyblok/composables';
import { renderRichText } from '~/modules/storyblok/helpers/renderRichText';

export default {
  name: 'RichText',
  props: {
    blok: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup({ blok }) {
    const { pcAlign, tabletAlign, align } = blok;
    const { centerTextStyle } = useStoryblokStyles();
    const centerTextClass = computed(() => centerTextStyle({ pcAlign, tabletAlign, align }));
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const richtext = computed((): ISbRichtext => renderRichText(blok['RichText'] as ISbStoryData));
    return {
      richtext,
      centerTextClass,
    };
  },
};
